.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 50px;
  text-align: center;
  padding: 1rem;
  background-color: #253b4d;
  border-top: 1px solid #dcdcdc;
  color: white;
}

.footer a {
  color: #dcdcdc;
}

.footer a:hover {
  color: grey;
}
