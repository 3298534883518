@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

.machine_info-transition-prev {
  /* This section calls the slideInFromLeft animation we defined above */
  animation: 0.5s ease-out 0s 1 slideInFromLeft;
}

.machine_info-transition-next {
  /* This section calls the slideInFromLeft animation we defined above */
  animation: 0.5s ease-out 0s 1 slideInFromRight;
}

.hide-scrollbar {
  overflow-x: hidden;
}

.machine-counter {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1em;
}

.machine-counter h3 {
  margin: 0;
}

.labeling-options_box {
  position: fixed;
  bottom: 0;
  width: 60%;
  height: 10em;
  text-align: center;
  padding: 1rem;
  background-color: rgba(37 59 77 / 80%);
  border-top: 1px solid #dcdcdc;
  color: white;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  border-radius: 15px 15px 0 0;
  display: flex;
  justify-content: space-around;
}

.labeling-icon {
  font-size: 4em;
  cursor: pointer;
}

.labeling-icon:hover {
  transform: scale(1.1);
}

.disabled {
  color: grey;
  cursor: default;
}

.disabled:hover {
  color: grey;
  cursor: default;
  transform: none;
}

.check-mark {
  opacity: 10;
}

.check-mark:hover {
  color: #08c308;
  opacity: 10;
}

.close {
  color: white;
  float: none;
  opacity: 10;
}

.close:hover {
  color: red;
}

.close:not(:disabled):not(.disabled):hover {
  opacity: 10;
}

.refresh {
  font-size: 2em;
}

.wanted {
  color: #08c308;
}

.not-wanted {
  color: red;
}

.change-model_form .msl-wrp {
  margin-bottom: 1em;
}

.change-model_form-buttons {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 1em;
}

.add-model_modal {
  margin: 5em;
  width: 100%;
  max-width: none;
}

.add-model_modal .modal-content {
  width: 90%;
}

.add-model-modal_close-button {
  cursor: pointer;
  font-size: 2em;
}

.add-model-modal_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.select_modal .modal-title {
  width: 100%;
}

.add-model_txt {
  display: flex;
  justify-content: center;
  margin-top: 0.5em;
}

.add-model_txt span:hover {
  cursor: pointer;
  text-decoration: underline;
}

.is-invalid .msl {
  border-color: #dc3545;
}

.match-manually_btn {
  background-color: #253b4d;
}

/* Loading effect */

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 2em;
  height: 2em;
  margin: 8px;
  border: 8px solid #253b4d;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #253b4d transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
