.searcher-container {
  margin-bottom: 2rem;
}

.search_options-toggle {
  display: inline-block;
  cursor: pointer;
  margin-top: 1rem;
}

.search_options-toggle span {
  margin-left: 0.5rem;
  user-select: none;
}

.reset-btn {
  color: #18a2b8;
  cursor: pointer;
}

.reset-btn:hover {
  color: #0f5e6a;
}

.input-group-text {
  padding: 0;
  border: 0;
}
