.login-form {
  width: 340px;
  margin: 50px auto;
}

.login-form form {
  margin-bottom: 15px;
  background: #f7f7f7;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.3);
  padding: 30px;
}

.login-form h2 {
  margin: 0 0 15px;
}

.form-control,
.btn {
  min-height: 38px;
  border-radius: 2px;
}

.login-btn {
  font-size: 15px;
  font-weight: bold;
}

.logo-container {
  width: 100%;
  text-align: center;
  margin-bottom: 2vw;
}

.login-logo {
  margin: 0 auto;
}

.auth-error {
  color: red;
}
