.brand-btn_container,
.model-btn_container {
  display: flex;
  justify-content: center;
  margin-top: 2em;
  padding: 0;
}

.add-brand_btn,
.add-model_btn {
  background-color: #253b4d;
  padding: 0 2em;
}

.add-brand_btn {
  margin-right: 0.05em;
}

.add-model_btn {
  margin-left: 0.05em;
}

.row-container {
  margin: 0;
  margin-top: 3em;
}

.form-container {
  padding: 0;
}

.add-model_modal .form-container {
  padding: 0 15em;
}

.collapse-container {
  width: 100%;
  padding-top: 4em;
}

.form-container input,
.form-container select,
.form-container .multiselect-brand_model {
  display: block;
  width: 19em;
  margin-bottom: 1rem;
  border-color: #767676;
  border-radius: 3px;
}

.form-container .multiselect-brand_model .msl-input,
.form-container .multiselect-brand_model .msl-single-value {
  padding: inherit;
}

.form_error-msg {
  color: #dc3545;
}

.required-label {
  font-weight: bold;
}

.form-container .is-invalid .msl {
  border-color: #dc3545;
}

.form-container input {
  border-color: hsl(0, 0%, 80%);
}
