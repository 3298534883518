/* TODO: enable tailwind to reset styles and remove bootstrap
@tailwind base; */

@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family:
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  position: relative;
  min-height: 100vh;
  padding-bottom: 5em;
}

.container {
  padding: 0 5vw;
}

.navbar-component {
  background-color: #253b4d;
  color: white;
}

.logo {
  margin-right: 1em;
}

.navbar-component a {
  color: inherit;
}

.navbar-brand {
  font-weight: bold;
  font-size: 1.5rem;
}

.dropdown {
  list-style: none;
}

a:hover {
  text-decoration: none;
}

.loader {
  background-image: url(../images/logo/gindumac-login-logo.2ae96a230949.png);
  width: 120px;
  height: 120px;
  margin: 0 auto;
  margin-top: 3rem;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
