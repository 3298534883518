

.machines_table-container td {
  max-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.entries_info {
  text-align: right;
}

.show_entries-selector select {
  display: inline-block;
  width: 30%;
}

.page-item {
  width: 2.5em;
  height: 2em;
}

.page-item button {
  box-sizing: content-box;
  width: 100%;
  height: 100%;
  padding: 0;
}

.show_entries-selector {
  padding: 0;
}

.selector_paginator-container {
  margin: 0;
}

.pagination-container {
  display: flex;
  justify-content: flex-end;
  padding: 0;
}

.page-link,
.page-item a {
  color: inherit;
}

.page-link:hover,
.page-item a:hover {
  color: inherit;
}

.current-page {
  background-color: #253b4d;
  color: white;
}

.current-page:hover {
  background-color: #253b4d;
}

.dissabled button {
  color: #dee2e6;
  pointer-events: none;
}

.th-container {
  display: flex;
  justify-content: space-between;
}

.field-selector {
  margin: 0.5em;
  padding: 0.5em;
  width: 90%;
}

.field-selected {
  background-color: rgba(0, 0, 0, 0.2);
  box-shadow: inset 4px 4px 15px 0 #696969;
}

.field-selected:focus {
  background-color: rgba(0, 0, 0, 0.2);
  box-shadow: inset 4px 4px 15px 0 #696969;
}

.visually-hidden {
  display: none;
}
